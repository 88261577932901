.about-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
}
.about-panel {
  width: 50vw;
  vertical-align: middle;
  margin: auto;
}
.about-bio {
  width: 90%;
}
.instagram-media {
  width: 90%;
  height: 50vh;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  display: block;
  margin: 0 20px;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

@media (max-width: 1024px) and (max-aspect-ratio: 5/6) {
  .about-container {
    flex-direction: column;
  }
  .about-container div {
    justify-content: center;
    text-align: center;
    margin: 10px auto 0px auto;
  }
  .about-panel {
    width: 100%;
  }
  .instagram-media {
    width: 90vw;
    height: 80vw;
    max-height: 600px;
    margin: auto;
  }
}

@media (max-height: 875px) and (min-aspect-ratio: 5/6) {
  .instagram-media {
    width: 47vw;
    height: 50vh;
  }
}