.navigation-container {
  position: fixed;
  display: flex;
  width: 100vw;
  max-height: 140px;
  padding-bottom: 10px;
  z-index: 1;
  flex-direction: column;
  text-align: center;
}
.nav-options {
  opacity: 0;
}
.navigation-container:hover .nav-options {
  opacity: 1;
}
.logo {
  /* top: 0; */
  height: 7vh;
  max-height: 55px;
  min-height: 30px;
  width: auto;
  margin: auto;
}
@media (pointer:none), (pointer:coarse), only screen and (max-width: 768px) {
  .navigation-container {
    background-color: black;
  }

  .nav-options {
    opacity: 1;
  }

  .logo {
    height: 6vh;
    max-height: 40px;
  }
}