.section-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  /* text-align: center; */
  /* align-items: center; */
  justify-content: center;
  color: white;
  flex-direction: column;
  flex: auto;
  flex-wrap: wrap;
  /* background-color: black; */
  scroll-snap-align: start;
}