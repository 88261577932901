#bg-video {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  object-fit: cover;
  opacity: 1;
  overflow: hidden;
}
.bandcamp-media {
  width: 39em;
  height: 50em;
  background-color: #333;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  display: block;
  margin: "0 0 12 0";
  padding: 0;
}

/* Vertical Mobile Device */
@media (max-width: 1024px) and (max-aspect-ratio: 5/6) {
  .bandcamp-media {
    width: 90vw;
    height: 67vh;
    max-width: 700px;
    max-height: 700px;
  }
}

/* Horizontal Mobile Device / Small Screen */
@media (max-height: 875px) and (min-aspect-ratio: 5/6) {
  .bandcamp-media {
    width: 30vw;
    height: 75vh;
    max-width: 600px;
    max-height: 600px;
  }
}