.bitw-container {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	margin: 0 auto;
	max-width: 70em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.bitw-custom-table-container {
  overflow-y: auto;
  max-height: 26vh;
  margin-bottom: 10px;
}

.bitw-custom-table-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(255,255,255,0.7);
}

.bitw-custom-table-container::-webkit-scrollbar
{
	width: 12px;
}

.bitw-custom-table-container::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.bitw-custom-table {
	border-collapse: collapse;
	border-spacing: 0;
	position: relative;
	width: 100%;
}

.bitw-custom-table:after {
	border-top: 1px solid currentColor;
	left: 0;
	opacity: .15;
	position: absolute;
	bottom: 0;
	width: 100%;
	content: '';
}

.bitw-custom-table p {
	margin: 0 10px 5px 10px;
	padding: 0;
  line-height: 1;
}

.bitw-custom-table td {
	position: relative;
}

.bitw-elm-bg {
  background-color: rgba(0,0,0,0.5);
}

.bitw-td-date {
	font-size: 1em;
	font-weight: 100;
	letter-spacing: .1em;
	line-height: 1.1;
	text-transform: uppercase;
	white-space: nowrap;
  text-align: center;
}

.bitw-custom-table td:first-of-type:before {
	border-top: 1px solid currentColor;
	left: 0;
	opacity: .15;
	position: absolute;
	top: 0;
	width: 100%;
	content: '';
}

.bitw-td-date-range {
	white-space: normal;
}

.bitw-custom-end-date, .bitw-custom-start-date {
	display: inline-block;
	white-space: nowrap;
}

.bitw-custom-seperator {
	display: inline-block;
	margin: 0 .25em;
	-webkit-transform: scaleX(2) scaleY(0.75);
	transform: scaleX(2) scaleY(0.75);
}

.bitw-inner-location {
	font-weight: 500;
	font-size: 2em;
}

.bitw-inner-notes {
	font-size: small;
	font-style: italic;
}

.bitw-td-tickets {
	vertical-align: middle;
}

.bitw-td-tickets a, .bitw-button{
	background: rgba(255, 255, 255, 0.25);
	border-radius: 2px;
	color: inherit;
	display: flex;
	font-size: 1em;
	font-weight: 100;
	letter-spacing: .09em;
	margin: .25ex 5px;
	min-width: 5em;
	padding: .5ex 1em;
	position: relative;
	text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
	text-decoration: none!important;
	text-transform: uppercase;
	-webkit-transition: .075s all;
	transition: .075s all;
}

.bitw-td-tickets a:after, .bitw-button:after {
	border-radius: 2px;
	bottom: 0;
	box-shadow: inset 0 0 0 1px currentColor;
	content: '';
	left: 0;
	opacity: .3;
	position: absolute;
	right: 0;
	top: 0;
}

.bitw-td-tickets a:focus, .bitw-td-tickets a:hover, .bitw-button:hover, .bitw-button:focus{
	background: rgba(0, 0, 0, 0.3);
	color: #fff;
	opacity: 1;
	outline: none;
}

.bitw-td-tickets a:focus:after, .bitw-td-tickets a:hover:after, .bitw-button:hover:after, .bitw-button:focus:after {
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
	opacity: .7;
}

.bitw-td-tickets a:active {
	background: rgba(0, 0, 0, 0.4);
	color: rgba(255, 255, 255, 0.7);
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

.bitw-td-tickets>span {
	display: inline-block;
	font-size: 1rem;
	margin: 0 .5ex;
}

.bitw-custom-sold-out a:before {
	content: 'Buy Tickets';
	display: block;
	margin: 0 .5em;
	text-decoration: line-through;
	white-space: nowrap;
}

.bitw-custom-highlighted td {
	background: rgba(0, 0, 0, 0.1);
}

span.bitw-outer-venue {
	font-size: 1em;
	font-weight: 100;
}
span.bitw-inner-location {
	letter-spacing: .04em;
  line-height: 1;
  margin-bottom: 0;
}
span.bitw-inner-venue {
	letter-spacing: 0.2em;
}
.bitw-hide {
  display: none;
}
.bitw-icon {
  margin-left: 10px;
}
.bitw-no-shows {
  background-color:rgba(0, 0, 0, 0.5);
}
@media (min-width: 30.001em) {
  .bitw-custom-table td:before {
    border-top: 1px solid currentColor;
    left: 0;
    opacity: .15;
    position: absolute;
    top: 0;
    width: 100%;
    content: '';
  }
	.bitw-td {
		padding: 16px 8px;
	}
  .bitw-td-date {
    max-width: 10em;
  }
  .bitw-inner-location {
    display: block;
    font-size: 1.5em;
  }
  .bitw-td-tickets {
    font-size: .1px;
    letter-spacing: -.5em;
    padding-right: 4px;
    text-align: right;
  }
  .bitw-td-tickets>span {
    max-width: 143px;
    /* margin-right: 20px; */
  }
  .bitw-td-venue-and-location {
    padding-left: 5%;
    max-width: 180px;
  }
}
@media (max-width: 30em) {
  .bitw-custom-table-container {
    max-height: 26vh;
    margin-bottom: 10px;
  }
  .bitw-custom-table {
    overflow: hidden;
  }
	.bitw-custom-table td:first-of-type {
		padding-top: 16px;
		padding-bottom: 0px;
	}
  .bitw-custom-table td:last-of-type {
    padding-bottom: 16px;
  }
  .bitw-td-date {
    font-size: 1.15em;
    margin-bottom: 5px;
  }
  .bitw-inner-venue, .bitw-inner-location {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .bitw-inner-venue {
    font-size: 0.8em;
  }
  .bitw-inner-location {
    font-size: 1.15em;
  }
  .bitw-td {
    display: block;
  }
}
@media (min-width: 1920px) and (min-height: 900px) and (min-aspect-ratio: 4/2) {
  .bitw-custom-table-container {
    max-height: 28vh;
  }
}

@supports (flex-wrap: wrap) {
	@media (max-width: 30em) {
		.bitw-td-tickets {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin: 0 -.5ex;
		}
	}
	.bitw-td-tickets>span {
		margin: auto;
	}
	.bitw-custom-buy-tickets {
		display: block;
	}
}