.footer {
  position: absolute;
  height: 120px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow-y: hidden;
  top: auto;
  margin-top: -120px;
  flex-direction: column;
  color: white;
}
/* .footer {
  position: fixed;
  height: 120px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow-y: hidden;
  top: auto;
  bottom: 0;
  flex-direction: column;
  color: white;
  z-index: -1;
} */
.footer-icon {
  font-size: 28px;
  margin: 10px;
}

@media (max-width: 768px), (max-height: 980px) {
  .footer {
    height: 60px;
    margin-top: -60px;
  }

  .footer-icon {
    font-size: 24px;
    margin: 5px 10px;
  }

  .footer-header {
    display: none;
  }
}

@media (max-width: 300px ) {
  .footer {
    height: 70px;
    margin-top: -65px;
  }

  .footer-icon {
    font-size: 20px;
    margin: 2px 10px;
  }
}

@media (min-width: 1920px) and (min-height: 1080px) and (min-aspect-ratio: 1) {
  .footer-icon {
    font-size: 36px;
    margin: 10px;
  }
}